import { SwipeableDrawer } from "@material-ui/core";
import React from "react";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import DateRange from '@material-ui/icons/DateRange';
import Assignment from '@material-ui/icons/Assignment';
import Folder from '@material-ui/icons/Folder';
import Group from '@material-ui/icons/Group';
import IconButton from "@material-ui/core/IconButton";
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from "clsx";
import { ListItemLink } from "./ListItemLink";
import ListSubheader from "@material-ui/core/ListSubheader";

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

const menuItems = [
	{to: "/projects", text: 'Projects', icon: <Folder/>},
	{to: "/tasks", text: 'Tasks', icon: <Assignment/>},
	{to: "/timesheet", text: 'Time sheet', icon: <DateRange/>},
];

const adminMenuItems = [
	{to: "/admin/users", text: 'Users', icon: <Group/>},
	{to: "/admin/taskdef", text: 'Tasks Def.', icon: <AssignmentLateIcon/>},
];

interface Props {
	isOpen: boolean;
	onOpen(): void;
	onClose(): void;
	classes: any;
}

export const Sidebar: React.FC<Props> = ({isOpen, onOpen, onClose, classes}) => {
	return (
		<SwipeableDrawer
			anchor="left"
			open={isOpen}
			onClose={onClose}
			onOpen={onOpen}
			disableBackdropTransition={!iOS}
			disableDiscovery={iOS}
			variant="permanent"
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: isOpen,
				[classes.drawerClose]: !isOpen,
			})}
			classes={{
				paper: clsx({
					[classes.drawerOpen]: isOpen,
					[classes.drawerClose]: !isOpen,
				}),
			}}
		>
			<div className={classes.toolbar}>
				<IconButton onClick={onClose}>
					<ChevronLeftIcon/>
				</IconButton>
			</div>
			<Divider/>
			<List>
				{menuItems.map((item, index) => (
					<ListItemLink key={item.to} to={item.to} primary={item.text} icon={item.icon} tooltip={!isOpen && item.text}/>
				))}
			</List>
			<Divider/>
			{isOpen && <ListSubheader component="div">
                Adminsitration
            </ListSubheader>}
			<List>
				{adminMenuItems.map((item, index) => (
					<ListItemLink key={item.to} to={item.to} primary={item.text} icon={item.icon} tooltip={!isOpen && item.text}/>
				))}
			</List>
		</SwipeableDrawer>
	);
}
