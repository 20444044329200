import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import configureAppStore from './store/configureStore';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import CssBaseline from "@material-ui/core/CssBaseline";
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import theme from "./theme";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const history = createBrowserHistory({basename: baseUrl});

const store = configureAppStore(history);

ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<ThemeProvider theme={theme}>
				<CssBaseline/>
				<App/>
			</ThemeProvider>
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root'));

registerServiceWorker();
