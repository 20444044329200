import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { configureStore } from '@reduxjs/toolkit'
import { History } from 'history';
import { reducers } from "./index";

export default function configureAppStore(history: History) {
	const middleware = [
		thunk,
		routerMiddleware(history)
	];

	const rootReducer = combineReducers({
		...reducers,
		router: connectRouter(history)
	});

	// const enhancers = [];
	// const windowIfDefined = typeof window === 'undefined' ? null : window as any;
	// if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
	//     enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
	// }
	return configureStore({
		reducer: rootReducer,
		middleware,
	});
}
