import * as React from 'react';
import Container from "@material-ui/core/Container";
import { NavMenu } from "./NavMenu";
import { PropsWithChildren } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { createStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
		},
		toolbar: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			padding: theme.spacing(0, 1),
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
		},
	}),
);


interface Props {
}

export const Layout: React.FC<PropsWithChildren<Props>> = (props) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<NavMenu/>
			<Container component="main" className={classes.content}>
				<>
					<div className={classes.toolbar}/>
					{props.children}
				</>
			</Container>
		</div>
	);
}
