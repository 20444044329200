import React from 'react';
import clsx from 'clsx';
import {createStyles, lighten, makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import MenuIcon from '@material-ui/icons/Menu';
import moment from "moment";
import {Switch} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import pink from "@material-ui/core/colors/pink";
import Modal from "@material-ui/core/Modal";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";

const closeDueDateMoment = moment().add(7, 'days');
console.log(closeDueDateMoment.format());
const todayDueDateMoment = moment();
const currentUser = 'Pavel';

interface Data {
    id: number;
    client: string;
    category: string;
    recurrence: string;
    name: string;
    dueDate: moment.Moment;
    status: string;
    assigned: string;
}

let serialId = 1;

function createData(
    client: string,
    category: string,
    recurrence: string,
    name: string,
    dueDate: string,
    status: string,
    assigned: string,
): Data {
    return {
        id: serialId++,
        client,
        category,
        recurrence,
        name,
        dueDate: moment(dueDate, 'DD.MM.YYYY'),
        status,
        assigned,
    };
}

const allRows = [
    createData('AA s.r.o.', 'DPH: Daňové přiznání', 'M01', 'Daňové přiznání_k_DPH_za_M01_/_2020', '20.10.2020', 'hotovo', 'Pavel'),
    createData('AA s.r.o.', 'DPH: Daňové přiznání', 'Q01', 'Daňové přiznání_k_DPH_za_Q3_/_2020', '15.10.2020', 'hotovo', 'Pavel'),
    createData('BB s.r.o.', 'Banka', '', 'Banka', '8.11.2020', 'čeká', 'Jana'),
    createData('C a.s.', 'Report banka', 'M01', 'Rozvaha a VZZ', '25.11.2020', 'čeká', 'Jana'),
    createData('XY s.r.o.', 'DPH: Daňové přiznání', 'M01', 'Daňové přiznání_k_DPH_za_M01_/_2020', '1.11.2020', 'po termínu', 'Eliška'),
    createData('XY s.r.o.', 'DPH: Daňové přiznání', 'M01', 'Daňové přiznání_k_DPH_za_M02_/_2020', '1.2.2020', 'hotovo', 'Eliška'),
    createData('XY s.r.o.', 'DPH: Daňové přiznání', 'M01', 'Daňové přiznání_k_DPH_za_M03_/_2020', '1.3.2020', 'hotovo', 'Pavel'),
    createData('Test co.', 'DPH: Daňové přiznání', 'M01', 'Daňové přiznání_k_DPH_za_M02_/_2020', '1.2.2020', 'hotovo', 'Eliška'),
    createData('Test co.', 'DPH: Daňové přiznání', 'M01', 'Daňové přiznání_k_DPH_za_M03_/_2020', '1.3.2020', 'hotovo', 'Pavel'),
    createData('Misc SE', 'Banka', '', 'Banka', '8.11.2020', 'čeká', 'Jana'),
    createData('Misc SE', 'Report banka', 'M01', 'Rozvaha a VZZ', '25.11.2020', 'čeká', 'Jana'),
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (typeof a === 'string' && typeof b === 'string') {
        return a.localeCompare(b);
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string | moment.Moment }, b: { [key in Key]: number | string | moment.Moment }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    {id: 'client', numeric: false, disablePadding: false, label: 'Firma'},
    {id: 'category', numeric: false, disablePadding: false, label: 'Kategorie'},
    {id: 'recurrence', numeric: false, disablePadding: false, label: 'Opakování'},
    {id: 'name', numeric: false, disablePadding: false, label: 'Název'},
    {id: 'dueDate', numeric: false, disablePadding: false, label: 'Termín'},
    {id: 'status', numeric: false, disablePadding: false, label: 'Stav'},
    {id: 'assigned', numeric: false, disablePadding: false, label: 'Přiřazen'},
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} = props;
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{'aria-label': 'select all desserts'}}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
								  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: '1 1 100%',
        },
    }),
);

interface EnhancedTableToolbarProps {
    numSelected: number;
    filter: TaskListFilterProps['filter'];
    onFilterChange: TaskListFilterProps['onChange'];
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const {numSelected} = props;

    return (
        <>
            <Toolbar
                className={clsx(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
            >
                {numSelected > 0 ? (
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                        Tasks
                    </Typography>
                )}
                <TaskListFilter filter={props.filter} onChange={props.onFilterChange}/>
                {numSelected > 0 ? (
                    <Tooltip title="Tasks actions">
                        <IconButton aria-label="action">
                            <MenuIcon/>
                        </IconButton>
                    </Tooltip>
                ) : (<></>
                    // <Tooltip title="Filter list">
                    // 	<IconButton aria-label="filter list" onClick={() => setShowFilter(!showFilter)}>
                    // 		<FilterListIcon/>
                    // 	</IconButton>
                    // </Tooltip>
                )}
            </Toolbar>
        </>
    );
};

interface TaskListFilterProps {
    filter: {
        myTasks: boolean;
        closeDueDate: boolean;
    };

    onChange(upd: Partial<TaskListFilterProps['filter']>): void;
}

const TaskListFilter: React.FC<TaskListFilterProps> = ({onChange, filter}) => (
    <div style={{ flexShrink: 0}}>
        <FormControlLabel
            control={<Switch checked={filter && filter.myTasks} onChange={(e, v) => onChange({myTasks: v})}/>}
            label="Jen moje"
        />
        <FormControlLabel
            control={<Switch checked={filter && filter.closeDueDate} onChange={(e, v) => onChange({closeDueDate: v})}/>}
            label="Jen aktuální"
        />
    </div>
);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        pastDueDate: {
            backgroundColor: 'pink',
        }
    }),
);

function isPastDueDate(row: Data) {
    return row.status !== 'hotovo' && row.dueDate.isSameOrBefore(todayDueDateMoment);
}

export const TasksList: React.FC = () => {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('client');
    const [selected, setSelected] = React.useState<number[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [filter, setFilter] = React.useState({myTasks: false, closeDueDate: false} as TaskListFilterProps['filter']);
    const [showDetailId, setShowDetailId] = React.useState(0);

    const rows = allRows.filter(row => {
        if (!filter) {
            return true;
        }
        if (filter.myTasks && row.assigned !== currentUser) {
            return false;
        }
        if (filter.closeDueDate && (row.status === 'hotovo' || row.dueDate.isAfter(closeDueDateMoment))) {
            return false;
        }
        return true;
    });

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        setShowDetailId(id);
    };

    const toggleSelected = (id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    const showDetailTask = showDetailId && allRows.find(r => r.id === showDetailId);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar numSelected={selected.length} filter={filter}
                                      onFilterChange={upd => setFilter({...filter, ...upd})}/>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={index}
                                            selected={isItemSelected}
                                            className={clsx(isPastDueDate(row) && classes.pastDueDate)}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{'aria-labelledby': labelId}}
                                                />
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                {row.client}
                                            </TableCell>
                                            <TableCell align="left">{row.category}</TableCell>
                                            <TableCell align="center">{row.recurrence}</TableCell>
                                            <TableCell align="left">{row.name}</TableCell>
                                            <TableCell align="center">{row.dueDate.format('DD.MM.YYYY')}</TableCell>
                                            <TableCell align="center">{row.status}</TableCell>
                                            <TableCell align="center">{row.assigned}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{height: (dense ? 33 : 53) * emptyRows}}>
                                    <TableCell colSpan={6}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 50]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            {/*<FormControlLabel*/}
            {/*	control={<Switch checked={dense} onChange={handleChangeDense}/>}*/}
            {/*	label="Dense padding"*/}
            {/*/>*/}
            {showDetailTask && (
                <Dialog
                    open={!!showDetailTask}
                    onClose={() => setShowDetailId(0)}
                >
                    <DialogTitle id="alert-dialog-title">{"Task detail: "}{showDetailTask.name}</DialogTitle>
                    <DialogContent>
                        {headCells.filter(h => !!showDetailTask[h.id]).map(h => <div key={h.id}><strong style={{ display: 'inline-block', width: 100}}>{h.label}</strong>{h.id === 'dueDate' ? showDetailTask[h.id].format('DD.MM.YYYY') : showDetailTask[h.id]}</div>)}
                        <br/>
                        <DialogContentText id="alert-dialog-description">
                            Detail úkolu s možností dokončení, předání na jiného řešitele, zadání poznámky nebo
                            příložení příloh.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {showDetailTask.status !== 'hotovo' && <Button onClick={() => {
                            showDetailTask.status = 'hotovo';
                            setShowDetailId(0);
                        }} color="primary">
                            Dokončit
                        </Button>}
                        <Button onClick={() => setShowDetailId(0)} color="primary" autoFocus>
                            Zavřít
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
