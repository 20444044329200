import React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";


interface Props {
	icon?: React.ReactElement;
	primary: string;
	tooltip?: string|false;
	to: string;
}

export const ListItemLink: React.FC<Props> = (props) => {
	const {icon, primary, to, tooltip} = props;

	const renderLink = React.useMemo(
		() =>
			React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
				<RouterLink to={to} ref={ref} {...itemProps} />
			)),
		[to],
	);

	const item = (
		<ListItem button component={renderLink}>
			{icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
			<ListItemText primary={primary}/>
		</ListItem>);

	return (
		<li>
			{tooltip ? <Tooltip placement="right" title={tooltip} arrow>{item}</Tooltip> : item}
		</li>
	);
}
