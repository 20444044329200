import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import HomePage from "./pages/HomePage";
import TasksPage from "./pages/TasksPage";

export default () => (
	<Layout>
		<Switch>
			<Route exact path='/' component={HomePage}/>
			<Route path='/projects' component={() => <><h1>Projects</h1>Do této sekce nemáte přístup.</>}/>
			<Route path='/tasks' component={TasksPage}/>
			<Route path='/timesheet' component={() => <><h1>Time sheet</h1>Do této sekce nemáte přístup.</>}/>
			<Route path='/admin/users' component={() => <><h1>Users administration</h1>Do této sekce nemáte přístup.</>}/>
			<Route path='/admin/taskdef' component={() => <><h1>Task definition</h1>Do této sekce nemáte přístup.</>}/>
			<Redirect to="/"/>
		</Switch>
	</Layout>
);
