import * as React from 'react';
import { connect } from 'react-redux';

const HomePage = () => (
	<div>
		<h1>Home</h1>
	</div>
);

export default connect()(HomePage);
