import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter
} from '@reduxjs/toolkit'

export interface Task {
    id: number;
    name: string;
}

export const fetchTasks = createAsyncThunk('tasks/all', async () => {
    return [{ id: 1, name: 'First'} , {id: 2, name: 'Second'}];
});

export const tasksAdapter = createEntityAdapter<Task>();

const initialState = tasksAdapter.getInitialState();

export const tasksSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        removeUser: tasksAdapter.removeOne
    },
    extraReducers: builder => {
        builder.addCase(fetchTasks.fulfilled, tasksAdapter.upsertMany);
    }
})

export const tasksReducer = tasksSlice.reducer;

export const { removeUser } = tasksSlice.actions;

