import * as React from 'react';
import { connect } from 'react-redux';
import { TasksList } from "../components/TasksList";

const TasksPage = () => (
	<div>
		<TasksList />
	</div>
);

export default connect()(TasksPage);
